<template>
  <NuxtLayout
    name="guest"
    title="Log in to your account"
    subtitle="Welcome back! Please enter your details."
  >
    <template #header-end>
      <AuthTabs />
    </template>
    <form @submit.prevent="onSubmit">
      <UiField
        layout="vertical"
        name="email"
        label="Email"
      >
        <UiTextInput
          id="email"
          name="email"
          type="email"
          icon="mail-01"
          placeholder="Enter your email"
          autofocus
          autocomplete="username"
        />
      </UiField>
      <UiField
        layout="vertical"
        name="password"
        label="Password"
        class="mt-5"
      >
        <UiTextInput
          id="password"
          name="password"
          type="password"
          placeholder="••••••••"
          autocomplete="current-password"
        />
      </UiField>
      <div class="mt-6 flex items-center justify-between">
        <UiCheckbox name="remember">
          Remember for 30 days
        </UiCheckbox>
        <UiLink to="#">
          Forgot password
        </UiLink>
      </div>
      <UiButton
        class="mt-6 w-full"
        :loading="isSubmitting"
      >
        Sign in
      </UiButton>
    </form>
    <template #footer>
      <p class="text-center text-sm text-gray-600">
        Don't have an account? <UiLink to="/register">
          Sign up
        </UiLink>
      </p>
    </template>
  </NuxtLayout>
</template>

<script lang="ts" setup>
definePageMeta({
  middleware: ['sanctum:guest'],
  layout: false,
})

interface LoginForm {
  email: string
  password: string
}

const { refreshIdentity } = useSanctumAuth()

const { onSubmit, isSubmitting } = useApiForm<LoginForm>({
  onSubmit: {
    url: '/login',
  },
  onSuccess: async () => {
    await refreshIdentity()
    await navigateTo('/')
  },
})
</script>
